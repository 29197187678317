@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply selection:bg-smooth-black dark:selection:bg-off-white selection:text-off-white dark:selection:text-smooth-black;
  }
  html {
    @apply max-h-screen overflow-hidden text-base antialiased font-sans;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-['Inter'];
  }
  a {
    @apply no-underline text-smooth-black dark:text-off-white;
  }
  article p img {
    @apply block ml-auto mr-auto object-contain rounded-lg shadow-lg;
  }
  pre {
    @apply shadow-lg font-mono;
  }
  code {
    @apply font-mono;
  }
  blockquote {
    @apply border-l-4 bg-light-grey border-teal-grey dark:text-off-white dark:bg-dark-grey rounded-lg shadow-lg;
  }
  .bg-glass {
    @apply transform duration-300 bg-[#f9f9f9] dark:bg-smooth-black border-r border-gray-200 dark:border-gray-800 backdrop-filter backdrop-blur-xl bg-opacity-80 dark:bg-opacity-80 firefox:bg-opacity-95 dark:firefox:bg-opacity-95;
  }
  .bg-panel {
    @apply transform duration-300 bg-[#f9f9f9] dark:bg-[#141414] border-r border-gray-200 dark:border-gray-800;
  }
  .focus-ring {
    @apply h-full focus:outline-none focus-within:outline-none transition duration-300 disabled:ring-0 hover:ring-2 focus:ring-2 focus-within:ring-2 group-hover:ring-2 group-focus:ring-2 hover:ring-teal-grey focus:ring-teal-grey focus-within:ring-teal-grey group-hover:ring-teal-grey group-focus:ring-teal-grey ring-teal-grey ring-offset-4 dark:ring-offset-gray-900 ring-offset-white;
  }
}

@layer utilities {
  .square {
    aspect-ratio: 1 / 1;
  }
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #8b949e;
}

.token.punctuation {
  color: #c9d1d9;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #79c0ff;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #a5d6ff;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #ff8527;
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #ff7b72;
}

.token.function,
.token.class-name {
  color: #d2a8ff;
}

.token.regex,
.token.important,
.token.variable {
  color: #c9d1d9;
}

.anchor {
  @apply absolute invisible;

  margin-left: -1em;
  padding-right: 0.5em;
  width: 80%;
  max-width: 700px;
  cursor: pointer;
}

.anchor:hover {
  @apply md:visible no-underline;
}

.anchor:after {
  @apply text-gray-300 dark:text-gray-700;
  content: "#";
}

*:hover > .anchor {
  @apply md:visible no-underline;
}

html {
  min-width: 360px;
  scroll-behavior: smooth;
}

body {
  line-height: 1.6;
  margin: 0;
}

img {
  max-width: 100%;
  display: block;
}

[id] {
  scroll-margin-top: 5rem;
}
